import styled from "styled-components";

import { IconTick } from "@assets/svg";

export const Label = styled.label`
  position: relative;
  display: flex;
  cursor: pointer;
`;

export const MarkWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.secondaryDecor};
  margin-right: 0.8125rem;
  color: transparent;
  flex-shrink: 0;
  transition: ${({ theme }) => theme.transition};
`;

export const MarkIcon = styled(IconTick)`
  height: 1em;
  width: 1.3em;
  font-size: 0.25rem;
  fill: currentColor;
`;

export const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  &:checked + ${MarkWrapper} {
    color: ${({ theme }) => theme.colors.primary};
  }
  &:disabled + ${MarkWrapper} {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.secondaryDecor};
  }
`;

export const Text = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: 0.625rem;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.secondary};
`;
