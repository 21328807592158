import React, { PropsWithChildren, forwardRef } from "react";

import FieldWrapper from "../FieldWrapper";

import { CheckboxProps } from "./types";
import * as Styled from "./styles";

const Checkbox = forwardRef<
  HTMLInputElement,
  PropsWithChildren<CheckboxProps>
>(({ label, disabled, error, children, ...rest }, ref) => (
  <FieldWrapper labelAsSpan {...{ label, disabled, error }}>
    <Styled.Label>
      <Styled.Input type="checkbox" {...{ ref, disabled, ...rest }} />
      <Styled.MarkWrapper>
        <Styled.MarkIcon />
      </Styled.MarkWrapper>
      <Styled.Text>{children}</Styled.Text>
    </Styled.Label>
  </FieldWrapper>
));

export default Checkbox;
