import styled from "styled-components";

import { textFieldCommonStyles } from "../commonStyles";

import { StyledInputProps } from "./types";

export const Input = styled.input<StyledInputProps>`
  ${({ withSuffix }) => textFieldCommonStyles(withSuffix)};
  height: 3rem;
  border-radius: 0;
`;
