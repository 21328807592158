import React, { forwardRef } from "react";

import FieldWrapper from "../FieldWrapper";

import { InputProps } from "./types";
import * as Styled from "./styles";

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ label, placeholder, disabled, error, suffix, ...rest }, ref) => (
    <FieldWrapper {...{ label, disabled, error, suffix }}>
      <Styled.Input
        {...{ ref, disabled, ...rest }}
        withSuffix={!!suffix}
        placeholder={placeholder || " "}
      />
    </FieldWrapper>
  )
);

export default Input;
