import React from "react";
import { storyblokEditable } from "@storyblok/react";

import HorizontalContactForm from "@components/organisms/forms/HorizontalContactForm";
import RichText from "@components/atoms/RichText";
import { getImage } from "@utils/getImage";

import { HorizontalContactFormStoryblokProps } from "./types";

const HorizontalContactFormStoryblok: React.FC<
  HorizontalContactFormStoryblokProps
> = ({ blok }) => {
  const {
    _uid: key,
    title,
    description,
    agreement,
    message,
    calendlyTitle,
    calendlyDescription,
    calendlyImage,
    calendlyExpert,
    calendlyLink: {
      url,
      cached_url: cachedUrl,
      rel,
      target,
      story: { full_slug: fullSlug = "" } = {}
    } = {},
    calendlyButtonLabel,
    redirectUrl
  } = blok;

  const newestUrl = fullSlug || cachedUrl;
  const href = url || `/${newestUrl}`;

  const item = {
    key,
    title,
    agreement: agreement?.[0]?.text?.content[0].content?.length ? (
      <RichText content={agreement?.[0].text} />
    ) : undefined,
    message,
    description: description?.[0]?.text?.content[0].content
      ?.length ? (
      <RichText content={description?.[0].text} />
    ) : undefined,
    calendlyTitle,
    calendlyDescription: calendlyDescription?.[0]?.text?.content[0]
      .content?.length ? (
      <RichText content={calendlyDescription?.[0].text} />
    ) : undefined,
    calendlyImage: getImage(calendlyImage),
    calendlyExpert: calendlyExpert?.[0]?.text?.content[0].content
      ?.length ? (
      <RichText content={calendlyExpert?.[0].text} />
    ) : undefined,
    calendlyButtonProps: {
      href,
      label: calendlyButtonLabel,
      rel,
      target
    },
    redirectUrl: redirectUrl?.url || redirectUrl?.cached_url
  };

  return (
    <HorizontalContactForm
      {...{
        ...item,
        ...storyblokEditable(blok)
      }}
    />
  );
};

export default HorizontalContactFormStoryblok;
