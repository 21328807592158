import { css } from "styled-components";

export const textFieldCommonStyles = (withSuffix?: boolean) => css`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  outline: none;
  font: inherit;
  color: ${({ theme }) => theme.colors.secondary};
  padding: ${withSuffix
    ? "0.5rem 3.0625rem 0.5rem 1rem"
    : "0.5rem 1rem"};
  border: 1px solid rgba(10, 43, 61, 0.3);
  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 3rem
      ${({ theme }) => theme.colors.white} inset;
  }
`;
