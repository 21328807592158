import styled from "styled-components";

import { textFieldCommonStyles } from "../commonStyles";

import { StyledTextareaProps } from "./types";

export const Textarea = styled.textarea<StyledTextareaProps>`
  ${({ withSuffix }) => textFieldCommonStyles(withSuffix)};
  display: block;
  min-height: 6.125rem;
  height: 100%;
  resize: none;
  border-radius: 0;
`;
