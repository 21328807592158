import { useState, useRef, useCallback, FormEvent } from "react";
import { useRouter } from "next/router";

import { API_URLS } from "../../../../api/consts";
import {
  errorMap,
  initialErrors,
  formRequirements,
  HIDE_MESSAGE_DELAY
} from "../consts";
import { FormDataProps, FormDataErrorProps } from "../types";

export const useContactForm = (redirectUrl?: string) => {
  const [isFormSent, setIsFormSent] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isSentSuccessfully, setIsSentSuccessfully] = useState(false);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [errors, setErrors] =
    useState<FormDataErrorProps>(initialErrors);
  const formWrapperRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const fullNameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const messageRef = useRef<HTMLTextAreaElement>(null);
  const rodoRef = useRef<HTMLInputElement>(null);

  const { push } = useRouter();

  const handleSetErrors = (values: FormDataErrorProps) => {
    setErrors(values);
  };

  const handleSetIsLoaderVisible = (isVisible: boolean) => {
    setIsLoaderVisible(isVisible);
  };

  const handleSetIsFormSubmitted = (isSubmitted: boolean): void => {
    setIsFormSubmitted(isSubmitted);
  };

  const handleSetIsSentSuccessfully = (status: boolean): void => {
    setIsSentSuccessfully(status);
  };

  const handleChangeFormStatus = (): void => {
    setIsFormSent(!isFormSent);
  };

  const handleResetForm = useCallback(() => {
    handleSetErrors(initialErrors);

    if (!formRef.current) return;

    formRef.current?.reset();
  }, []);

  const handleScrollToMessage = () => {
    if (!formWrapperRef.current) return;

    formWrapperRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "center"
    });
  };

  const handleSendForm = async (values: FormDataProps) => {
    try {
      const { fullName, email, phone, message, rodo } = values;

      const response = await fetch(API_URLS.mail, {
        body: JSON.stringify({
          fullName,
          email,
          phone,
          message,
          rodo
        }),
        headers: {
          "Content-Type": "application/json"
        },
        method: "POST"
      });

      if (redirectUrl && response.ok) {
        await push(redirectUrl);
      } else {
        handleSetIsSentSuccessfully(!!response.ok);
      }
    } catch (error) {
      handleSetIsSentSuccessfully(false);
    } finally {
      handleSetIsLoaderVisible(false);
      handleSetIsFormSubmitted(false);
      handleChangeFormStatus();
      handleResetForm();
      handleScrollToMessage();

      setTimeout(() => {
        setIsFormSent(false);
      }, HIDE_MESSAGE_DELAY);
    }
  };

  const handleValidate = ({
    fullName,
    email,
    phone,
    message,
    rodo
  }: FormDataProps) => {
    const validatedErrors = { ...initialErrors };
    const { minLength, maxLength } = formRequirements;
    const { required, tooShort, tooLong, invalidEmail } = errorMap;

    if (fullName.length === 0) {
      validatedErrors.fullName = required;
    }

    if (fullName.length < minLength && fullName.length > 0) {
      validatedErrors.fullName = tooShort;
    } else if (fullName.length > maxLength) {
      validatedErrors.fullName = tooLong;
    }

    if (message.length === 0) {
      validatedErrors.message = required;
    }

    if (message.length < minLength && message.length > 0) {
      validatedErrors.message = tooShort;
    }

    if (email.length === 0) {
      validatedErrors.email = required;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      validatedErrors.email = invalidEmail;
    }

    if (phone.length < minLength && phone.length > 0) {
      validatedErrors.phone = tooShort;
    } else if (phone.length > maxLength) {
      validatedErrors.phone = tooLong;
    }

    if (!rodo) {
      validatedErrors.rodo = required;
    }

    return validatedErrors;
  };

  const handleOnChange = () => {
    if (!isFormSubmitted) return;

    const values = {
      fullName: fullNameRef.current?.value || "",
      message: messageRef.current?.value || "",
      email: emailRef.current?.value || "",
      phone: phoneRef.current?.value || "",
      rodo: rodoRef.current?.checked || false
    };

    handleSetErrors(handleValidate(values));
  };

  const handleOnSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    handleSetIsLoaderVisible(true);

    const values = {
      fullName: fullNameRef.current?.value || "",
      message: messageRef.current?.value || "",
      email: emailRef.current?.value || "",
      phone: phoneRef.current?.value || "",
      rodo: rodoRef.current?.checked || false
    };

    const formErrors = handleValidate(values);

    handleSetErrors(formErrors);

    const isValid = !Object.values(formErrors).some(
      (item) => item.length
    );

    handleSetIsFormSubmitted(true);

    if (!isValid) {
      handleSetIsLoaderVisible(false);

      return;
    }

    handleSendForm(values);
  };

  return {
    isSentSuccessfully,
    isFormSent,
    isLoaderVisible,
    errors,
    formWrapperRef,
    formRef,
    fullNameRef,
    emailRef,
    phoneRef,
    messageRef,
    rodoRef,
    handleOnSubmit,
    handleOnChange,
    handleValidate,
    handleResetForm
  };
};
