import styled, { keyframes, css } from "styled-components";
import { FlexBox } from "@pagepro-monorepo/ui/lib/components/styles/Grid";
import shouldForwardProp from "@pagepro-monorepo/ui/lib/utils/shouldForwardProp";

import { LoaderProps } from "./types";

const loading = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Wrapper = styled(FlexBox).withConfig({
  shouldForwardProp
})<LoaderProps>`
  position: absolute;
  inset: 0;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 999;
  animation: ${loading} 1s linear;
  transition: ${({ theme }) => theme.transition};
  transition-property: opacity;
  opacity: 0;
  visibility: hidden;

  ${({ $isVisible }) =>
    $isVisible &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

export const Circle = styled.span`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 2.75rem;
  height: 2.75rem;
  margin: 0.25rem;
  border: 0.25rem solid ${({ theme }) => theme.colors.accent};
  border-radius: 50%;
  animation: ${loading} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${({ theme }) => theme.colors.accent} transparent
    transparent transparent;
`;

export const Container = styled.div`
  position: relative;
  width: 3.25rem;
  height: 3.25rem;

  ${Circle}:nth-child(1) {
    animation-delay: -0.45s;
  }

  ${Circle}:nth-child(2) {
    animation-delay: -0.3s;
  }

  ${Circle}:nth-child(3) {
    animation-delay: -0.15s;
  }
`;
