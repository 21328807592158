import { useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useRouter } from "next/router";
import { useTheme } from "styled-components";
import Button from "@pagepro-monorepo/ui/lib/components/atoms/Button";
import Typography from "@pagepro-monorepo/ui/lib/components/styles/Typography";
import {
  FlexBox,
  Box
} from "@pagepro-monorepo/ui/lib/components/styles/Grid";

import Image from "@components/atoms/Image";
import Checkbox from "@components/atoms/fields/Checkbox";
import ButtonLink from "@components/atoms/ButtonLink";
import Loader from "@components/atoms/Loader";

import { useContactForm } from "./hooks";
import { HorizontalContactFormProps } from "./types";
import * as Styled from "./styles";

const HorizontalContactForm: React.FC<HorizontalContactFormProps> = ({
  title,
  description,
  agreement,
  message,
  calendlyTitle,
  calendlyDescription,
  calendlyImage,
  calendlyExpert,
  calendlyButtonProps,
  redirectUrl
}) => {
  const { asPath } = useRouter();
  const { formatMessage } = useIntl();
  const {
    isSentSuccessfully,
    isFormSent,
    isLoaderVisible,
    errors,
    formWrapperRef,
    formRef,
    fullNameRef,
    emailRef,
    phoneRef,
    messageRef,
    rodoRef,
    handleOnSubmit,
    handleOnChange,
    handleResetForm
  } = useContactForm(redirectUrl);
  const theme = useTheme();

  useEffect(() => {
    handleResetForm();
  }, [asPath, handleResetForm]);

  const { label, ...rest } = calendlyButtonProps || {};

  return (
    <Styled.Wrapper
      ref={formWrapperRef}
      gridTemplateColumns={["1fr", "1fr", "repeat(2, 1fr)"]}
      aria-label="Horizontal contact form"
      className={isSentSuccessfully ? "is-contact-form-sent" : ""}
    >
      <Loader $isVisible={isLoaderVisible} />
      <Styled.Column>
        {title && <Styled.Title>{title}</Styled.Title>}
        <Box mb={10}>
          {description && (
            <Styled.Description>
              {typeof description === "string" ? (
                <Typography variant="body4">{description}</Typography>
              ) : (
                description
              )}
            </Styled.Description>
          )}
        </Box>
        <Styled.Form ref={formRef} onSubmit={handleOnSubmit}>
          <Styled.FieldWrapper isValid={!errors.fullName}>
            <Styled.InputField
              ref={fullNameRef}
              name="fullName"
              label={formatMessage({
                defaultMessage: "Full name"
              })}
              className={
                errors.fullName
                  ? "is-contact-form-fullName-error"
                  : ""
              }
              onChange={handleOnChange}
            />
            {errors.fullName ? (
              <Styled.Error>{errors.fullName}</Styled.Error>
            ) : null}
          </Styled.FieldWrapper>
          <Styled.FieldWrapper isValid={!errors.email}>
            <Styled.InputField
              ref={emailRef}
              name="email"
              type="email"
              label={formatMessage({
                defaultMessage: "E-mail address"
              })}
              className={
                errors.email ? "is-contact-form-email-error" : ""
              }
              onChange={handleOnChange}
            />
            {errors.email ? (
              <Styled.Error>{errors.email}</Styled.Error>
            ) : null}
          </Styled.FieldWrapper>
          <Styled.FieldWrapper isValid={!errors.phone}>
            <Styled.InputField
              ref={phoneRef}
              name="phone"
              type="tel"
              label={formatMessage({
                defaultMessage: "Phone"
              })}
              className={
                errors.phone ? "is-contact-form-phone-error" : ""
              }
              onChange={handleOnChange}
            />
            {errors.phone ? (
              <Styled.Error>{errors.phone}</Styled.Error>
            ) : null}
          </Styled.FieldWrapper>
          <Styled.FieldWrapper isValid={!errors.message}>
            <Styled.TextareaField
              ref={messageRef}
              name="message"
              label={formatMessage({
                defaultMessage: "Message"
              })}
              className={
                errors.message ? "is-contact-form-message-error" : ""
              }
              onChange={handleOnChange}
            />
            {errors.message ? (
              <Styled.Error>{errors.message}</Styled.Error>
            ) : null}
          </Styled.FieldWrapper>
          {agreement && (
            <Styled.Content>
              <Styled.FieldWrapper isValid={!errors.rodo}>
                <Checkbox
                  ref={rodoRef}
                  name="rodo"
                  className={
                    errors.rodo ? "is-contact-form-rodo-error" : ""
                  }
                  onChange={handleOnChange}
                >
                  {agreement &&
                    (typeof agreement === "string" ? (
                      <Typography variant="body12">
                        {agreement}
                      </Typography>
                    ) : (
                      agreement
                    ))}
                </Checkbox>
                {errors.rodo ? (
                  <Styled.Error>{errors.rodo}</Styled.Error>
                ) : null}
              </Styled.FieldWrapper>
            </Styled.Content>
          )}
          <Styled.Action>
            <Button $mode="tertiary" type="submit">
              <FormattedMessage defaultMessage="Send message" />
            </Button>
          </Styled.Action>
        </Styled.Form>
      </Styled.Column>
      <Styled.Column
        backgroundColor={theme.colors.primary}
        color={theme.colors.white}
      >
        <FlexBox
          flexDirection="column"
          alignItems="flex-start"
          height="100%"
        >
          {calendlyTitle && (
            <Styled.Title>{calendlyTitle}</Styled.Title>
          )}
          {calendlyDescription &&
            (typeof calendlyDescription === "string" ? (
              <Typography variant="body4">
                {calendlyDescription}
              </Typography>
            ) : (
              calendlyDescription
            ))}
          {calendlyImage ? (
            <Styled.ImageWrapper>
              <Image
                {...calendlyImage}
                width={130}
                height={130}
                style={{
                  objectFit: "cover"
                }}
              />
            </Styled.ImageWrapper>
          ) : null}
          {calendlyExpert &&
            (typeof calendlyExpert === "string" ? (
              <Typography variant="body4">
                {calendlyExpert}
              </Typography>
            ) : (
              calendlyExpert
            ))}
          <Styled.CalendlyAction>
            <ButtonLink
              className="ga-calendly-cta"
              aria-label="Book a meeting with an expert with calendly"
              {...{ ...rest }}
            >
              {label || (
                <FormattedMessage defaultMessage="Book a meeting" />
              )}
            </ButtonLink>
          </Styled.CalendlyAction>
        </FlexBox>
      </Styled.Column>
      {isFormSent && (
        <Styled.MessageWrapper
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p="3.5rem"
          color={theme.colors.primary}
        >
          {isSentSuccessfully ? <Styled.SuccessIcon /> : null}
          <Styled.Message>
            {isSentSuccessfully
              ? `${message}`
              : formatMessage({
                  defaultMessage: "An unexpected error occurred"
                })}
          </Styled.Message>
        </Styled.MessageWrapper>
      )}
    </Styled.Wrapper>
  );
};

export default HorizontalContactForm;
