export const companyDomain = "pagepro";

export const API_URLS = {
  mail: "/api/sendemail",
  mailBackground: "/api/sendemail/background",
  contacts: "/marketing/contacts",
  searchContactsByEmail: "/marketing/contacts/search/emails",
  newsletter: "/api/newsletter",
  proxy: "/api/proxy",
  download: "/api/download"
};

// Inbound Website Lead In Stage ID
export const pipedriveStageId = 36;

export enum ErrorCodes {
  EMAIL_ALREADY_ASSIGNED = 4001
}
