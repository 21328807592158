import LazyHydrate from "@components/utils/LazyHydrate";

import { LoaderProps } from "./types";
import * as Styled from "./styles";

const Loader: React.FC<LoaderProps> = ({ $isVisible = false }) => (
  <LazyHydrate whenVisible noWrapper>
    <Styled.Wrapper
      alignItems="center"
      justifyContent="center"
      {...{ $isVisible }}
    >
      <Styled.Container>
        <Styled.Circle />
        <Styled.Circle />
        <Styled.Circle />
      </Styled.Container>
    </Styled.Wrapper>
  </LazyHydrate>
);

export default Loader;
