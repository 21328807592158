export const errorMap = {
  required: "Required",
  tooShort: "Too Short",
  tooLong: "Too Long",
  invalidEmail: "Invalid email",
  invalidPhone: "Invalid phone, please use international format"
};

export const initialErrors = {
  fullName: "",
  email: "",
  phone: "",
  message: "",
  rodo: ""
};

export const formRequirements = {
  minLength: 3,
  maxLength: 50
};

export const HIDE_MESSAGE_DELAY = 5000;
