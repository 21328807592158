import styled, { css } from "styled-components";
import Typography from "@pagepro-monorepo/ui/lib/components/styles/Typography";
import {
  FlexBox,
  GridBox,
  Box
} from "@pagepro-monorepo/ui/lib/components/styles/Grid";
import shouldForwardProp from "@pagepro-monorepo/ui/lib/utils/shouldForwardProp";

import Input from "@components/atoms/fields/Input";
import Textarea from "@components/atoms/fields/Textarea";
import MessageSent from "@assets/svg/message-sent.svg";

import { StyledFieldWrapperProps } from "../types";

export const InputField = styled(Input)``;

export const TextareaField = styled(Textarea)``;

export const CalendlyAction = styled.div`
  margin-top: auto;
  padding-top: 3rem;
`;

export const ImageWrapper = styled.div`
  margin: 2.5rem 0;

  img {
    border-radius: 50%;
  }
`;

export const FieldWrapper = styled.div<StyledFieldWrapperProps>`
  position: relative;
  margin-bottom: 0.75rem;

  ${({ isValid }) =>
    !isValid &&
    css`
      color: ${({ theme }) => theme.colors.accentDecor};

      ${InputField},
      ${TextareaField} {
        color: ${({ theme }) => theme.colors.accentDecor};
        border-bottom: 1px solid
          ${({ theme }) => theme.colors.accentDecor};
      }
    `}
`;

export const Title = styled(Typography).attrs({
  forwardedAs: "p",
  variant: "subheading2"
})`
  margin-bottom: 1.125rem;
`;

export const Description = styled.div`
  margin-bottom: 1.125rem;
`;

export const Column = styled(Box)
  .withConfig({
    shouldForwardProp
  })
  .attrs({
    p: ["3rem 2rem", "3rem 4rem", "3rem"]
  })`
  display: flex;
  flex-direction: column;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Wrapper = styled(GridBox)`
  position: relative;
  z-index: 1;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0.25rem 3.125rem rgba(0, 0, 0, 0.15);
`;

export const Figure = styled.figure`
  position: absolute;
`;

export const Action = styled.div`
  padding-top: 1.875rem;
  margin-top: auto;
`;

export const Error = styled.span`
  display: inline-block;
  font-size: 0.75rem;
  line-height: 2.5;
  margin-left: 0.75rem;
`;

export const Content = styled.div`
  margin-top: 1.125rem;

  ${Error} {
    left: 1.5rem;
  }
`;

export const MessageWrapper = styled(FlexBox)`
  position: absolute;
  inset: 0;
  background-color: ${({ theme }) => theme.colors.white};
  text-align: center;
`;

export const SuccessIcon = styled(MessageSent)`
  margin-bottom: 4rem;
  fill: ${({ theme }) => theme.colors.secondary};
`;

export const Message = styled(Typography).attrs({
  variant: "subheading2",
  forwardedAs: "p"
})`
  margin-bottom: 2.25rem;
  color: ${({ theme }) => theme.colors.primary};
`;
