import styled, { css } from "styled-components";
import Typography from "@pagepro-monorepo/ui/lib/components/styles/Typography";

import { StyledFieldWrapperProps } from "./types";

export const LabelText = styled.span<StyledFieldWrapperProps>`
  display: block;
  opacity: 0.6;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 0.875rem;
`;

export const Label = styled.label<StyledFieldWrapperProps>`
  display: block;
  width: 100%;
  position: relative;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: 1rem;
  line-height: 2;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `}
`;

export const Error = styled(Typography).attrs({
  variant: "body6"
})`
  color: ${({ theme }) => theme.colors.accent};
  display: block;
  margin-top: 0.25rem;
`;

export const FieldWrapper = styled.span`
  width: 100%;
  position: relative;
  display: block;
`;

export const Suffix = styled.span`
  position: absolute;
  height: 3rem;
  width: 3.0625rem;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    height: 1em;
    width: 1em;
    font-size: 0.5rem;
  }
`;
